@import url('https://fonts.googleapis.com/css2?family=Jura&display=swap');

* {
  margin: 0;
  padding: 0;
  background-color: black;
  font-family: "Jura", sans-serif;
  color: whitesmoke;
}

.content {
  text-align: center;
}

.links a {
  padding: 35px 0px 0px 35px;
  display: flex;
  text-decoration: none;
  font-size: 25px;
  color: gray;
}

.links a:hover {
  color: gold;
  cursor: crosshair;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.header {
  overflow: hidden;
  border-right: .15em;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  padding-top: 20px;
  text-align: center;
  font-size: 35px;
  animation: typewriter 3s steps(40) 1s forwards; 
}

@media only screen and (max-width: 380px) {
  .header {
    font-size: 30px;
  }

  .intro {
    font-size: 25px;
  }
}

.intro {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.socials {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Sedan:ital@0;1&display=swap");


.footer-text {
  font-family: "Sedan", serif;
  font-weight: 400;
  font-style: normal;
}

.logo {
  margin: 10px;
  color: white;
  border: 2px solid white;
  padding: 2px 5px; 
  display: inline-block; 
  text-decoration: none; 
  cursor: crosshair;
}

.logo:hover, .logo:active {
  text-decoration: none; 
  color: gray;
}